import React from 'react';
import * as Styled from './greyCardBlogStyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';
import { Body } from '../../styles';

const GreyCardWithImageAndLinks = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
        target = {
          isExternalUrl(node?.data?.uri)? '_blank' : '_self'
        }>{children}</Styled.LinkStyle>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItemArrow>{children}</Styled.ListItemArrow>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
        href={node?.data?.uri }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.CardImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Body>
      <Styled.BlockQuote>
        {sectionData?.header?.trim() && (
          <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
        )}
        {sectionData?.subText?.trim() && (
          <Styled.SubText>{sectionData?.subText}</Styled.SubText>
        )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
      </Styled.BlockQuote>
    </Body>
  );
};

export default GreyCardWithImageAndLinks;
